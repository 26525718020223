var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0 }}},[(!_vm.isSuccess)?_c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['role']),expression:"['role']"}],attrs:{"name":"radioGroup","default-value":1}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" Teacher ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" Student ")])],1)],1),_c('p',{staticClass:"text"},[_vm._v("Personal profile")]),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your first and last name!',
                      } ],
                  } ]),expression:"[\n                  'name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your first and last name!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"First and last name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'birthday',
                  {
                    rules: [
                      { required: true, message: 'Please enter your date of birth!' } ],
                  } ]),expression:"[\n                  'birthday',\n                  {\n                    rules: [\n                      { required: true, message: 'Please enter your date of birth!' },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"DOB"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'gender',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please choose your gender!',
                      } ],
                  } ]),expression:"[\n                  'gender',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please choose your gender!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"default-value":"female","placeholder":"Gender"}},[_c('a-select-option',{attrs:{"value":"female"}},[_vm._v(" Female ")]),_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" Male ")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['school']),expression:"['school']"}],attrs:{"placeholder":"School"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'province',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your province!',
                      } ],
                  } ]),expression:"[\n                  'province',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select your province!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"show-search":"","placeholder":"Choose province"}},_vm._l((_vm.listProvince),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1),_c('p',{staticClass:"text"},[_vm._v("Account Information")]),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your account name!',
                      } ],
                  } ]),expression:"[\n                  'username',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your account name!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Account name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your email!',
                      } ],
                  } ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your email!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Email"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your phone number!',
                      } ],
                  } ]),expression:"[\n                  'phone',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your phone number!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Phone number"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your password!',
                      } ],
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your password!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"validate-status":_vm.checkRepass ? 'success' : 'error'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'rePass',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      } ],
                  } ]),expression:"[\n                  'rePass',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please confirm your password!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"password","placeholder":"Confirm password"},model:{value:(_vm.repassword),callback:function ($$v) {_vm.repassword=$$v},expression:"repassword"}})],1)],1)],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(" I agree the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit","disabled":!_vm.checked}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign In")])],1)],1):_c('div',[_c('b',[_vm._v("Enter the confirmation code sent to your email")]),_c('v-otp-input',{attrs:{"disabled":_vm.loading},on:{"finish":_vm.onFinish},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),(!_vm.resendCode)?_c('p',[_vm._v(" Haven't received the code? "),_c('b',{staticClass:"forgot-password",on:{"click":_vm.resendEmail}},[_vm._v("Resend code")])]):_vm._e()],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/bg-signup.jpg')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Register")])])])}]

export { render, staticRenderFns }